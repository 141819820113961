var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Header'),_c('Nav'),_vm._m(0),_c('div',{staticClass:"home-wrap"},[_c('div',{staticClass:"home-module"},[_c('ul',[_c('li',{on:{"click":function($event){return _vm.goOther('https://www.cas-expo.org.cn/en/register.html')}}},[_c('img',{attrs:{"src":require("./img/icon-1.png")}}),_c('p',[_vm._v("قاعة التسجيل")])]),_c('li',{on:{"click":function($event){return _vm.goOther('https://www.cas-expo.org.cn/en/meetCalen.html')}}},[_c('img',{attrs:{"src":require("./img/icon-2.png")}}),_c('p',[_vm._v("جدول الأعمال")])]),_c('li',{on:{"click":function($event){return _vm.goOther('https://www.cas-expo.org.cn/en/exGuide.html')}}},[_c('img',{attrs:{"src":require("./img/icon-3.png")}}),_c('p',[_vm._v("دليل المؤتمر")])]),_c('li',{on:{"click":function($event){return _vm.goOther('https://www.cas-expo.org.cn/en/hallGuide.html')}}},[_c('img',{attrs:{"src":require("./img/icon-4.png")}}),_c('p',[_vm._v("دليل المعرض")])])])]),_c('div',{staticClass:"home-exhibition"},[_c('h2',{staticClass:"home-exhibition__title"},[_vm._v("لدورة السادسة لمعرض الصين والدول العربية")]),_c('div',{staticClass:"home-exhibition__desc"},[_vm._v("المعرض على الإنترنت")]),_c('a',{staticClass:"home-exhibition__btn",on:{"click":function($event){return _vm.goOther('https://www.cas-expo.org.cn/en/planeCloud.html')}}},[_vm._v("دخول المعرض")])]),_c('div',{staticClass:"home-news"},[_c('div',{staticClass:"home-news__list"},[_c('ul',[_c('li',{on:{"click":_vm.gotoNews}},[_vm._m(1)]),_vm._m(2),_vm._m(3)])]),_vm._m(4)])]),_vm._m(5),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-banner"},[_c('img',{attrs:{"src":require("@/assets/img/itemBanners.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('img',{attrs:{"src":require("@/assets/img/222.jpg")}}),_c('div',{staticClass:"home-news__title"},[_vm._v("المكتب التجاري في نينغشيا: معرض الصين الدولي لتجارة الخدمات يساعد نينغشيا في الترويج للمعرض الصيني العربي")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_c('img',{attrs:{"src":require("@/assets/img/111.jpg")}}),_c('div',{staticClass:"home-news__title"},[_vm._v("العناصر العربية في معرض تجارة الخدمات.. لمس مقدم للمعرض الصيني العربي في معرض تجارة الخدمات")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_c('img',{attrs:{"src":require("@/assets/img/333.png")}}),_c('div',{staticClass:"home-news__title"},[_vm._v("معرض الصين الدولي لتجارة الخدمات يساعد منطقة نينغشيا الذاتية الحكم على الترويج لمعرض الصين والدول العربية")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-news__video"},[_c('video',{attrs:{"controls":"","controlsList":"nodownload","src":require("./img/video.mp4")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-meeting"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/img/itemList.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }