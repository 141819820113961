<template>
  <div class="footer">
    <img src="@/assets/img/itemBottom.jpg" alt="">
    <!-- <div class="footer-top">
      <div class="footer-link">
        <h2>لجهات المستضيفة</h2>
        <ul>
          <li>
            <a>الحكومة الشعبية لمنطقة نينغشيا ذاتية الحكم لقومية هوي</a>
          </li>
        </ul>
      </div>
      <div class="footer-link">
        <h2>لجهات المنظمة</h2>
        <ul>
          <li>
            <a>وزارة التجارة لجمهورية الصين الشعبية</a>
          </li>
          <li>
            <a>اللجنة الوطنية الصينية لتنمية التجارة الدولية</a>
          </li>
          <li>
            <a>الحكومة الشعبية لمنطقة نينغشيا ذاتية الحكم لقومية هوي</a>
          </li>
        </ul>
      </div>
      <div class="footer-logo">
        <img src="./img/logo.png"/>
      </div>
    </div>
    <div class="footer-copyright">
      <span style="margin-right: 10px;">:ICP</span>
      <span style="margin-right: 10px;">نينغشيا رقم تسجيل</span>
      <span style="margin-right: 30px;">7-05001183</span>
      <span>كل الحقوق محفوظة لدى الأمانة العامة لمعرض الصين والدول العربية</span>
    </div> -->
  </div>
</template>

<script>
import './index.scss'
export default {

}
</script>