<template>
  <div class="home">
    <Header/>
    <Nav/>
    <div class="home-banner">
      <img src="@/assets/img/itemBanners.png"/>
    </div>
    <div class="home-wrap">
      <div class="home-module">
        <ul>
          <li @click="goOther('https://www.cas-expo.org.cn/en/register.html')">
            <img src="./img/icon-1.png"/>
            <p>قاعة التسجيل</p>
          </li>
          <li @click="goOther('https://www.cas-expo.org.cn/en/meetCalen.html')">
            <img src="./img/icon-2.png"/>
            <p>جدول الأعمال</p>
          </li>
          <li @click="goOther('https://www.cas-expo.org.cn/en/exGuide.html')">
            <img src="./img/icon-3.png"/>
            <p>دليل المؤتمر</p>
          </li>
          <li @click="goOther('https://www.cas-expo.org.cn/en/hallGuide.html')">
            <img src="./img/icon-4.png"/>
            <p>دليل المعرض</p>
          </li>
        </ul>
      </div>

      <div class="home-exhibition">
        <h2 class="home-exhibition__title">لدورة السادسة لمعرض الصين والدول العربية</h2>
        <div class="home-exhibition__desc">المعرض على الإنترنت</div>
        <a class="home-exhibition__btn" @click="goOther('https://www.cas-expo.org.cn/en/planeCloud.html')">دخول المعرض</a>
      </div>

      <div class="home-news">
        <div class="home-news__list">
          <ul>
            <li @click="gotoNews">
              <a>
                <img src="@/assets/img/222.jpg"/>
                <div class="home-news__title">المكتب التجاري في نينغشيا: معرض الصين الدولي لتجارة الخدمات يساعد نينغشيا في الترويج للمعرض الصيني العربي</div>
              </a>
            </li>
            <li>
              <a>
                <img src="@/assets/img/111.jpg"/>
                <div class="home-news__title">العناصر العربية في معرض تجارة الخدمات.. لمس مقدم للمعرض الصيني العربي في معرض تجارة الخدمات</div>
              </a>
            </li>
            <li>
              <a>
                <img src="@/assets/img/333.png"/>
                <div class="home-news__title">معرض الصين الدولي لتجارة الخدمات يساعد منطقة نينغشيا الذاتية الحكم على الترويج لمعرض الصين والدول العربية</div>
              </a>
            </li>
          </ul>
        </div>
        <div class="home-news__video">
          <video controls controlsList="nodownload" src="./img/video.mp4"/>
        </div>
      </div>
    </div>
    <div class="home-meeting">
        <img src="@/assets/img/itemList.jpg" alt="" style="width: 100%;">
        <!-- <div class="home-meeting__wrap">
          <div class="home-meeting__title">الاجتماعات والمنتديات</div>
          <div class="home-meeting__list">
            <div
              v-for="(item,index) in meetingList"
              class="home-meeting__item"
              :key="index"
            >
              <a>{{ item.name }}</a>
            </div>
          </div>
        </div> -->
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'

import './index.scss'
export default {
  components: {
    Header,
    Nav,
    Footer
  },
  data() {
    return {
      meetingList: [
        {name: 'مؤتمر "الحزام والطريق" لتنمية التجارة والاستثمار',link: ''},
        {name: 'حفل الافتتاح',link: ''},
        {name: 'مؤتمر التعاون في التنمية عالية الجودة للزراعة الحديثة',link: ''},
        {name: 'قمة الأعمال الصينية العربية',link: ''},
        {name: 'مؤتمر طريق الحرير الإلكتروني',link: ''},
        {name: 'مؤتمر التعاون في نقل التكنولوجيا والابتكار',link: ''},
        {name: 'منتدى صناعة الصحة الشاملة',link: ''},
        {name: 'منتدى الموارد المائية',link: ''},
        {name: 'منتدى التعاون للتنمية عالية الجودة في مجال الأرصاد الجوية',link: ''},
        {name: 'المؤتمر الصيني العربي للوكالات السياحية',link: ''},
      ]
    }
  },
  methods: {
    gotoNews() {
      this.$router.push({
        name: 'news'
      })
    },
    goOther(url) {
      window.open(url)
    }
  }
}
</script>

<style>

</style>